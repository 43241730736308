import styled from "styled-components";
import {Link as LinkR} from "react-router-dom";

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1f2041;
  min-height: 100vh;
`

export const LegalHeader = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 10px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #119da4;
  box-shadow: 0 17px 29px 0 rgba(0,0,0,0.75);
`

export const HLogo = styled.img`
  width: 20%;
  padding: 5px;
  border-radius: 1em;
  background-color: #1f2041;
`

export const LegalNotes = styled.div`
  min-height: calc(90vh - 80px);
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
  background-color: #119da4;
  border-radius: 20px;
  padding: 21px;
  
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  h4 {
    text-align: center;
    width: 70%;
    margin-bottom: 5px;
  }
  
  h1 {
    margin-bottom: 20px; 
  }
`

export const LegalElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  background-color: #1f2041;
  padding: 5px 30px;
  border-radius: 10px;
  margin-bottom: 5px;
`

export const LegalTitle = styled.p`
  color: #FA9905;
  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
`

export const LegalNote = styled.p`
  color: #f7f8fa;
  
  @media screen and (max-width: 768px) {
    font-size: 0.5rem;
  }
`

export const NavLink = styled(LinkR)`
`