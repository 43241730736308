import React from 'react'
import LegalNotice from "../components/LegalNotice";

const ComingSoon = () => {
    return (
        <>
            <LegalNotice />
        </>
    );
}

export default ComingSoon