export default [
    {
        legalType: "Geschäftsführer",
        legalValue: "Jonas Schweigler"
    },
    {
        legalType: "Tel. Number",
        legalValue: "+43 676 842014200"
    },
    {
        legalType: "Email",
        legalValue: "business@subaze.com"
    },
    {
        legalType: "Adresse",
        legalValue: "Hamet 33"
    },
    {
        legalType: "Stadt",
        legalValue: "Jagerberg"
    },
    {
        legalType: "Land",
        legalValue: "Steiermark"
    },
    {
        legalType: "Staat",
        legalValue: "Österreich"
    },
]