import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/index';
import ComingSoon from "./pages/coming-soon";

function App() {

  return (
      <Router>
          <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/impressium' component={ComingSoon} exact />
          </Switch>
      </Router>
  )
}

export default App
