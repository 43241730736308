import React, {useState} from 'react'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroP, HeroH1, ArrowForward, ArrowRight, HeroBtnWrapper } from './HeroElements'
import {Button} from "../ButtonElement";

const HeroSection = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const style1 = {
        color: '#fa9905'
    }

    const style2 = {
        color: '#ff5200'
    }

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg autoplay={true} loop={true} muted={true} responsive={true} src="f43749f76603b3b102dcfbb505bc06ed" />
            </HeroBg>
            <HeroContent>
                <HeroH1>More than just an App.</HeroH1>
                <HeroP>
                    <span style={style1}>Sub</span><span style={style2}>aze</span>
                </HeroP>
                <HeroBtnWrapper>
                    <Button to='signup' to='signup'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            activeClass='active'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary='true'
                            dark='true'>
                        Jetzt downloaden {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
}

export default HeroSection