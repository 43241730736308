import React, {useEffect, useState} from "react";
import { Nav, NavLogo, NavBarContainer, NavItem, NavLinks, NavMenu, MobileIcon, NavBtn, NavBtnLink } from "./NavBarElements";
import { FaBars } from 'react-icons/fa';
import {IconContext} from "react-icons/lib";
import {animateScroll as scroll} from "react-scroll"
import Subaze from "../../images/Subaze.svg"

const NavBar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const style1 = {
    color: '#fa9905'
  }

  const style2 = {
    color: '#ff5200'
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
      <Nav scrollNav={scrollNav}>
        <NavBarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img  src={Subaze} alt={"logo"}/>
            <span style={style1}>Sub</span><span style={style2}>aze</span>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to='about'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              activeClass='active'
              >Über Uns</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='discover'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              activeClass='active'
              >Ziele</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='services'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              activeClass='active'
              >Vorteile</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='profits'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              activeClass='active'
              >Warum Uns</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='signup'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              activeClass='active'
              >Download</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to='/impressium'>Starte als Creator</NavBtnLink>
          </NavBtn>
        </NavBarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;
