import React from "react";
import Logo from "../../images/Subaze.svg";
import { LegalContainer, LegalHeader, HLogo, LegalNotes, LegalElement, LegalTitle, LegalNote, NavLink } from "./LegalElements";
import data from "./Data";
import {ButtonR} from "../ButtonElement";

function LegalNotice() {
    return (
        <LegalContainer>
            <LegalHeader>
                <NavLink to="/">
                    <HLogo src={Logo} alt={"logo"} />
                </NavLink>
                <ButtonR to='/' primary={true}>Home</ButtonR>
            </LegalHeader>
            <LegalNotes>
                <h1>Impressium</h1>
                <h4>Wenn Sie ein Creator sind, dann kontaktieren Sie uns bitte, da wir noch nicht offiziel gelaunched haben. Aber wir suchen täglich nach Influencern, die den ersten Schritt wagen wollen. Wir machen Ihnen gerne ein Angebot als Startvorteil.</h4>
                <div className={"wrapper"}>
                    {data.map((legal, key) => (
                        <LegalElement key={key}>
                            <LegalTitle>{legal.legalType}:</LegalTitle>
                            <LegalNote>{legal.legalValue}</LegalNote>
                        </LegalElement>
                    ))}
                </div>
            </LegalNotes>
        </LegalContainer>
    );
}

export default LegalNotice