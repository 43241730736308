import styled from 'styled-components'
import {Link as LinkR} from "react-router-dom"

export const FooterWrapper = styled.div`
  /*code*/
  background-color: #FA9905;
  .footer-parts {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    a {
      margin: 5px;
      color: #0d0d0d;
      font-size: 1.2rem;

      @media only screen and (max-width: 765px) {
        font-size: 0.8rem !important;
      }
    }
    
  }
`

export const FooterElement = styled(LinkR)`
  margin: 5px;
  color: #0d0d0d;
  font-size: 1.2rem;

  @media only screen and (max-width: 765px) {
    font-size: 0.8rem !important;
  }
`
