import React from 'react'
import { ServicesContainer, ServicesCard, ServicesH1, ServicesIcon, ServicesH2, ServicesP, ServicesWrapper } from  './ServicesElements'
import icon1 from '../../images/icon-1.svg'
import icon2 from '../../images/icon-2.svg'
import icon3 from '../../images/icon-3.svg'

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Vorteile</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={icon3}/>
                    <ServicesH2>Events</ServicesH2>
                    <ServicesP>Supporte Projekte oder Events von Influencern deiner Wahl.</ServicesP>
                </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={icon1}/>
                        <ServicesH2>Subs</ServicesH2>
                        <ServicesP>Mit Subs kannst du dir Vorteile bei deinem Lieblings Creator kaufen.</ServicesP>
                    </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={icon2}/>
                            <ServicesH2>Rangliste</ServicesH2>
                            <ServicesP>Nehme an verschiedensten Donation Spielen teil um den erten Platz zu erlangen.</ServicesP>
                        </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    );
}

export default Services