import React from 'react';
import { FooterWrapper, FooterElement } from './FooterElements';

function Footer() {
    return(
        <>
            <FooterWrapper>
                <div className='footer-parts'>
                    <FooterElement to="/impressium">
                        Impressium
                    </FooterElement>
                    <FooterElement to="/">
                        |
                    </FooterElement>
                    <a href="mailto:business@subaze.com">
                            business@subaze.com
                    </a>
                </div>
            </FooterWrapper>
        </>
    )
}

export default Footer