/*import Car from "../../images/svg-1.svg";*/
import Intro from '../../images/svg-1.svg'
import Finance from "../../images/svg-2.svg";
import Premium from "../../images/svg-4.svg";
import WhyUs from "../../images/svg-3.svg";


/***************************************/

//About

export const homeObjOne = {
  id: "about",
  IdPoint: 'home',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Unser Team",
  headline: "24/7 Support.",
  description:
    "Unsere Leidenschaft liegt bei der Unterstützung unserer Nutzer. Wir sind ein Team aus professionellen Angestellten aus allen benötigten Bereichen.",
  buttonLabel: "Mehr erfahren",
  imgStart: false,
  img: Intro,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

//Goal

export const homeObjTwo = {
  id: "discover",
  IdPoint: 'home',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unsere Mission",
  headline: "Unser Ziel ist es, das Donation System breiter zu fächern.",
  description:
    "Unser Ziel ist es, durch die Neugestaltung von Donations eine neue Welt des Supports zu schaffen. Heute haben die meisten Donation-Apps immer wieder dieselbe Funktionalität, aber wir hoffen, mit Subaze ein vielfältigeres Support-System für Streamer und YouTuber (Vlogger) zu schaffen.",
  buttonLabel: "Mehr erfahren",
  imgStart: true,
  img: Finance,
  alt: "finance",
  dark: false,
  primary: false,
  darkText: true,
};

//Download

export const homeObjThree = {
  id: "signup",
  IdPoint: 'home',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Download",
  headline: "IOS / Android",
  description:
    "Unsere App ist noch in der Entwicklung und wir suchen noch nach einem Youtuber als Partner. Falls Sie einer sind, so würden wir uns freuen, wenn Sie uns kontaktieren würden. Klicken Sie einfach auf den Button 'Starte als Influencer'.",
  buttonLabel: "Mehr erfahren",
  imgStart: false,
  img: Premium,
  alt: "premium",
  dark: true,
  primary: true,
  darkText: false,
};

/**************************************/

// Why US

export const homeObjFive = {
  id: "profits",
  IdPoint: 'home',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Warum Uns",
  headline: "Unsere Leidenschaft ist dein Vergnügen.",
  description:
      "In unserer App bekommst du die Möglichkeit auf verschideneste Arten zu Donaten zu allen Zeitpunkten ohne dabei anonym zu bleiben. Durch die Ranglisten der einzelnen Donation Spiele kannst du deinen Rum als stolzen Donater aufbauen.",
  buttonLabel: "Mehr erfahren",
  imgStart: true,
  img: WhyUs,
  alt: "finance",
  dark: false,
  primary: false,
  darkText: true,
};